<template>
  <NavBar :progress="85" />
  <div class="outer-container">
    <router-link :to="`/klachten/${$route.params.opticienId}/${$route.params.companyId}`">
      <img class="arrow-back" alt="arrow" src="../assets/arrow-left.svg">
    </router-link>
    <div class="top-container">
      <h2 class="content-title">Advies</h2>
      <h3 class="content-subject">Bedankt voor jouw antwoorden</h3>
      <div v-if="getResult.advice">
        <h4>Waarschijnlijk heb jij <b>wel</b> profijt van een beeldschermbril. Wil je je aanvraag<br> indienen bij de HR manager, rond dan deze aanvraag af.</h4>
        <router-link :to="`/gegevens/${$route.params.opticienId}/${$route.params.companyId}`">
          <div class="button primary">Rond aanvraag af</div>
        </router-link>
      </div>
      <div v-else>
        <h4>Waarschijnlijk heb jij <b>geen</b> profijt van een beeldschermbril. Wil je je <br>aanvraag toch indienen bij de HR manager, rond dan deze aanvraag af.</h4>
        <router-link :to="`/gegevens/${$route.params.opticienId}/${$route.params.companyId}`">
          <div class="button secondary">Toch afronden</div>
        </router-link>
      </div>
    </div>
    <div class="bottom-container">
      <span>Jouw antwoorden</span>
      <div>
        <h5>Leeftijd</h5>
        <p>In welke leeftijdscategorie val jij?</p>
        <h6>{{ getResult.age.age }}</h6>
      </div>
      <div>
        <h5>Schermtijd</h5>
        <p>Werk jij met regelmaat meer dan twee uur per dag op een beeldscherm?</p>
        <div v-if="getResult.screenTime">Ja</div>
        <div v-else style="color: #777777">Nee</div>
      </div>
      <div>
        <h5>Bril of/en lenzen</h5>
        <p>Draag je al een(lees) bril, multifocale bril of contactlenzen op je werk of privé?</p>
        <div v-if="getResult.glassesAndOrLenses">Ja</div>
        <div v-else style="color: #777777">Nee</div>
      </div>
      <div>
        <h5>Zithouding</h5>
        <p>Denk of weet jij dat je krom achter jouw beeldscherm zit?</p>
        <div v-if="getResult.sittingPosition">Ja</div>
        <div v-else style="color: #777777">Nee</div>
      </div>
      <div>
        <h5>Klachten</h5>
        <p>Heb je nek-, rugklachten of schouderklachten?</p>
        <div v-if="getResult.complaints">Ja</div>
        <div v-else style="color: #777777">Nee</div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Advice',
  components: {
    NavBar,
  },
  created() {
    this.calculateAdvice();
  },
  computed: {
    ...mapGetters(['getResult']),
  },
  methods: {
    ...mapActions(['calculateAdvice']),
  },
}
</script>

<style lang="scss" scoped>
  .outer-container {
    width: 40rem;
  }

  .top-container {
    margin-top: 2.5rem;
    border-bottom: 0.05rem solid #b8b8b8;
    h4 {
      font-size: 0.89rem;
      font-weight: 400;
      margin-bottom: 1.5rem;
    }
    .button {
      margin-bottom: 2rem;
    }
    a {
      text-decoration: none;
      color: inherit;
    }
  }

  .bottom-container {
    margin-top: 1rem;
    span {
      font-size: 0.8rem;
      font-weight: 600;
      margin-bottom: 1.1rem;
    }
    div {
      border-bottom: 0.05rem solid #b8b8b8;
      h5 {
        font-size: 0.9rem;
        color: #141e8c;
        margin-top: 1rem;
        margin-bottom: -0.4rem;
      }
      p {
        margin-bottom: 1.1rem;
        font-size: 0.89rem;
        color: #424242;
      }
      h6 {
        color: #000000;
        font-size: 1.1rem;
        font-weight: 500;
        float: right;
        margin-top: -2.3rem;
      }
      div {
        float: right;
        padding: 0.4rem 0.4rem 0.4rem 0.4rem;
        width: 3.2rem;
        text-align: center;
        border: 0.08rem solid #d1d1d1;
        font-size: 1.1rem;
        font-weight: 500;
        color: #141e8c;
        margin-top: -3.55rem;
        border-radius: 0.2rem; 
      }
    }
  }
</style>
