<template>
  <div class="multipleChoice">
    <p>Meerdere antwoorden mogelijk.</p>
    <MultipleChoiceOption
      :option="option"
      v-for="option in options"
      v-bind:key="option.value"
      @selected:value="updateSelectedOptions"
      :selected="selectedOptions[option.value]"
    />
  </div>
</template>

<script>
import MultipleChoiceOption from "@/components/MultipleChoiceOption";

export default {
  name: "MultipleChoice",
  components: {MultipleChoiceOption},
  emits: [
      'update:selectedOptions',
  ],
  props: {
    options: {
      type: Array
    },
    selectedOptions: {
      type: Object
    }
  },
  methods: {
    updateSelectedOptions(value) {
      let tmpSelectedOptions = {...this.selectedOptions};
      tmpSelectedOptions[value] = !tmpSelectedOptions[value];

      this.$emit('update:selectedOptions', tmpSelectedOptions);
    }
  }
}
</script>

<style lang="scss" scoped>
  .multipleChoice {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    p {
      color: #999B9C;
      font-size: 1.2rem;
    }
  }
</style>