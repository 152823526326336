<template>
  <div :class="['scaleOption', {'selected': selected}]" @click="$emit('update:selected', value)">{{ value }}</div>
</template>

<script>
export default {
  name: 'ScaleOption',
  emits: ['update:selected'],
  props: {
    value: {
      type: Number
    },
    selected: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
    }
  },
  computed: {
    cssColor: {
      get() {
        return this.color;
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  $size: 3.3rem;

  .scaleOption {
    border: 0.15rem solid v-bind(cssColor);
    border-radius: 50%;
    height: $size;
    width: $size;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    font-size: 1.25rem;
    user-select: none;

    &:not(.selected) {
      background: v-bind(cssColor);
      color: white;
    }

    &.selected {
      background: transparent;
      color: v-bind(cssColor);
    }
  }
</style>
