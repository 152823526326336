<template>
  <NavBar :progress="50" />
  <div class="outer-container">
    <router-link :to="`/bril-of-en-lenzen/${$route.params.opticienId}/${$route.params.companyId}`">
      <img class="arrow-back" alt="arrow" src="../assets/arrow-left.svg">
    </router-link>
    <div class="container">
      <img alt="stoel" src="../assets/beeldschermbril-stoel.svg">
      <h2 class="content-title">Zithouding</h2>
      <h3 class="content-subject">Pas jij je lichaamshouding aan, aan je beeldscherm?</h3>
      <div class="explanation-container">
        <div class="explanation-container-arrow" />
        <p>TOELICHTING</p>
        <span>Wanneer je minder goed ziet en je zit achter een computer, dan ga je bijna automatisch ‘in je scherm kruipen’. Je rekt je nek wat uit, trekt je schouders omhoog en buigt naar het scherm toe. Vraag het maar eens aan collega’s in je omgeving.</span>
      </div>
      <div class="options-container">
        <div class="button primary" @click="updateResult(true)">Ja</div>
        <div class="pipe" />
        <div class="button secondary" @click="updateResult(false)">Nee</div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import { mapMutations } from 'vuex'

export default {
  name: 'SittingPosition',
  components: {
    NavBar,
  },
  methods: {
    ...mapMutations(['setSittingPosition']),
    updateResult(boolean) {
      this.setSittingPosition(boolean);
    },
  }
}
</script>

<style lang="scss" scoped>
  .container {
    margin-top: 8.4rem;
    > img {
      width: 10rem;
      margin-left: -13.3rem;
      margin-bottom: -10.8rem;
    }
  }
</style>
