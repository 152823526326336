<template>
  <NavBar :progress="100" titleSuffix="tevredenheidsonderzoek" customBase="vragenlijst/" />
  <div class="outer-container">
    <router-link :to="{ name: 'SurveyOtherGlasses', params: { 'opticienId': $route.params.opticienId, 'companyId': $route.params.companyId } }">
      <img class="arrow-back" alt="arrow" src="../../assets/arrow-left.svg">
    </router-link>
    <div class="question">
      <div class="content-title">
        10 / 10
      </div>
      <h3 class="content-subject">
        Wil je nog iets kwijt over jouw ervaringen met je<br/>
        nieuwe beeldschermbril, onze hulp of jouw<br/>
        bezoek aan de winkel?
      </h3>
      <textarea v-model="currentValue"/>
      <div class="buttons">
        <div class="button primary" @click="finish(currentValue)">Verzend</div>
        <div class="button secondary" @click="finish(undefined)">Sla over</div>
      </div>
    </div>
  </div>
</template>

<script>
  import NavBar from "@/components/NavBar";
  import {createNamespacedHelpers} from "vuex";
  const {mapActions, mapGetters, mapMutations} = createNamespacedHelpers('survey');

  export default {
    name: "SurveyReview",
    components: {
      NavBar
    },
    methods: {
      ...mapGetters(["getOtherGlasses", "getReview"]),
      ...mapMutations(["setReview"]),
      ...mapActions(["clearAnswers", "submitToApi"]),
      async finish(value) {
        this.currentValue = value;
        try {
          await this.submitToApi(this.$route.params.companyId);

          await this.$router.push({
            name: 'SurveyThanks',
            params: {
              'opticienId': this.$route.params.opticienId,
              'companyId': this.$route.params.companyId
            }
          })
        } catch (e) {
          this.clearAnswers();
          await this.$router.push({
            name: 'SurveyWelcome',
            params: {
              'opticienId': this.$route.params.opticienId,
              'companyId': this.$route.params.companyId
            }
          });
          alert("Het is niet goed gegaan met het opslaan van je data, probeer het opnieuw of neem contact op met je HR medewerker.");
        }
      }
    },
    computed: {
      currentValue: {
        get() {
          return this.getReview();
        },
        set(value) {
          this.setReview(value);
        }
      }
    },
    created() {
      // Navigate back when no previous answer found.
      if (this.getOtherGlasses() === undefined) this.$router.push(
          {
            name: 'SurveyOtherGlasses',
            params: {
              'opticienId': this.$route.params.opticienId,
              'companyId': this.$route.params.companyId
            }
          }
      );
    }
  }
</script>

<style lang="scss" scoped>
  .content-title {
    font-weight: bold;
  }

  .question {
    margin-top: 8rem;

    display: inline-flex;
    flex-direction: column;
    gap: 1.5rem;

    h3 {
      margin: 0;
    }

    textarea {
      resize: none;
      min-height: 10rem;

      border: 1px solid #8B8D8E;
      border-radius: 0.35rem;
      padding: 0.5rem;
      outline: none;

      font-size: 1rem;
      line-height: 1.2rem;
      font-family: 'Frutiger', sans-serif;

      &:focus {
        border: 1px solid #008BD0;
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
    }
  }
</style>