<template>
  <NavBar :progress="90" titleSuffix="tevredenheidsonderzoek" customBase="vragenlijst/" />
  <div class="outer-container">
    <router-link :to="{ name: 'SurveyRecommend', params: { 'opticienId': $route.params.opticienId, 'companyId': $route.params.companyId } }">
      <img class="arrow-back" alt="arrow" src="../../assets/arrow-left.svg">
    </router-link>
    <div class="question">
      <div class="content-title">
        9 / 10
      </div>
      <h3 class="content-subject">
        Draag je naast de beeldschermbril ook wel eens<br/>
        een andere bril of contactlenzen?
      </h3>
      <MultipleChoice :options="multipleChoiceOptions" :selectedOptions="getOtherGlasses()" @update:selectedOptions="setOtherGlasses($event)"/>
      <div class="button primary" @click="goNextQuestion">Oké</div>
    </div>
  </div>
</template>

<script>
  import NavBar from "@/components/NavBar";
  import MultipleChoice from "@/components/MultipleChoice";
  import {createNamespacedHelpers} from "vuex";
  const {mapGetters, mapMutations} = createNamespacedHelpers('survey');

  export default {
    name: "SurveyOtherGlasses",
    components: {
      NavBar,
      MultipleChoice
    },
    data() {
      return {
        multipleChoiceOptions: [
          {
            label: "Leesbril",
            value: 'leesbril',
          },
          {
            label: "Bril op sterkte",
            value: 'bril_op_sterkte',
          },
          {
            label: "Zonnebril op sterkte",
            value: 'zonnebril_op_sterkte',
          },
          {
            label: "Multifocale/Varifocale bril",
            value: 'multifocale_varifocale_bril',
          },
          {
            label: "Contactlenzen",
            value: 'contactlenzen',
          },
          {
            label: "Nachtlenzen",
            value: 'nachtlenzen',
          },
        ],
        selectedOptions: {},
      }
    },
    methods: {
      ...mapGetters(['getRecommend', 'getOtherGlasses']),
      ...mapMutations(['setOtherGlasses']),
      goNextQuestion() {
        this.$router.push({
          name: 'SurveyReview',
          params: {
            'opticienId': this.$route.params.opticienId,
            'companyId': this.$route.params.companyId
          }
        });
      }
    },
    created() {
      if (this.getOtherGlasses()) {
        this.selectedOptions = this.getOtherGlasses();
      } else {
        // Create selectedOptions from multipleChoiceOptions
        this.multipleChoiceOptions.forEach(value => this.selectedOptions[value.value] = false);
        this.setOtherGlasses(this.selectedOptions);
      }

      // Navigate back when no previous answer found.
      if (this.getRecommend() === undefined) {
        this.$router.push(
            {
              name: 'SurveyRecommend',
              params: {
                'opticienId': this.$route.params.opticienId,
                'companyId': this.$route.params.companyId
              }
            }
        );
      }
    }
  }
</script>

<style lang="scss" scoped>
  .content-title {
    font-weight: bold;
  }

  h3 {
    margin-bottom: -0.5rem;
  }

  .question {
    margin-top: 8rem;

    display: inline-flex;
    flex-direction: column;

    .button {
      margin-top: 2rem;
    }
  }
</style>