import axios from "axios";

export default {
    namespaced: true,
    state: {
        usedOther: undefined,
        satisfied: undefined,
        workingPosition: undefined,
        tiredEyes: undefined,
        sharperVision: undefined,
        lessPain: undefined,
        service: undefined,
        recommend: undefined,
        otherGlasses: undefined,
        review: undefined,
    },
    getters: {
        getUsedOther: state => state.usedOther,
        getSatisfied: state => state.satisfied,
        getWorkingPosition: state => state.workingPosition,
        getTiredEyes: state => state.tiredEyes,
        getSharperVision: state => state.sharperVision,
        getLessPain: state => state.lessPain,
        getService: state => state.service,
        getRecommend: state => state.recommend,
        getOtherGlasses: state => state.otherGlasses,
        getReview: state => state.review,
        getState: state => state,
    },
    actions: {
        clearAnswers({commit}) {
            commit('setUsedOther', undefined);
            commit('setSatisfied', undefined);
            commit('setWorkingPosition', undefined);
            commit('setTiredEyes', undefined);
            commit('setSharperVision', undefined);
            commit('setLessPain', undefined);
            commit('setService', undefined);
            commit('setRecommend', undefined);
            commit('setOtherGlasses', undefined);
            commit('setReview', undefined);
        },
        async submitToApi({state}, companyId) {
            if (companyId) {
                let requestBody = {
                    "campaign": "Beeldschermbril",
                    "companyId": companyId,
                    "answers": {...state}
                };

                await axios.post(`https://zeiss-api.leadq.nl/GenericApi/Endpoint/postKTOResponses`, requestBody);
            }
        }
    },
    mutations: {
        setUsedOther(state, value) {
            state.usedOther = value;
        },
        setSatisfied(state, value) {
            state.satisfied = value;
        },
        setWorkingPosition(state, value) {
            state.workingPosition = value;
        },
        setTiredEyes(state, value) {
            state.tiredEyes = value;
        },
        setSharperVision(state, value) {
            state.sharperVision = value;
        },
        setLessPain(state, value) {
            state.lessPain = value;
        },
        setService(state, value) {
            state.service = value;
        },
        setRecommend(state, value) {
            state.recommend = value;
        },
        setOtherGlasses(state, value) {
            state.otherGlasses = value;
        },
        setReview(state, value) {
            state.review = value;
        },
    }
};
