<template>
  <NavBar :progress="25" />
  <div class="outer-container">
    <router-link :to="`/leeftijd/${$route.params.opticienId}/${$route.params.companyId}`">
      <img class="arrow-back" alt="arrow" src="../assets/arrow-left.svg">
    </router-link>
    <div class="container">
      <img alt="beeldscherm" src="../assets/beeldschermbril-scherm.svg">
      <h2 class="content-title">Schermtijd</h2>
      <h3 class="content-subject">Werk je met regelmaat meer dan twee uur per<br> dag op een beeldscherm?</h3>
      <div class="explanation-container">
        <div class="explanation-container-arrow" />
        <p>TOELICHTING</p>
        <span>Tel hierbij niet alleen de uren achter je computer of tablet. Maar tel ook de tijd op dat je tijdens je werk op een telefoonscherm kijkt. Daarbij gaat het niet om bellen, maar om kijken.</span>
      </div>
      <div class="options-container">
        <div class="button primary" @click="updateResult(true)">Ja</div>
        <div class="pipe" />
        <div class="button secondary" @click="updateResult(false)">Nee</div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import { mapMutations } from 'vuex'

export default {
  name: 'ScreenTime',
  components: {
    NavBar,
  },
  methods: {
    ...mapMutations(['setScreenTime']),
    updateResult(boolean) {
      this.setScreenTime(boolean);
    }
  },
}
</script>

<style lang="scss" scoped>
  .container {
    margin-top: 8.5rem;
    > img {
      width: 11.5rem;
      margin-left: -14.5rem;
      margin-bottom: -9.4rem;
    }
  }
</style>
