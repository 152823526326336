<template>
  <NavBar :progress="0" titleSuffix="tevredenheidsonderzoek" customBase="vragenlijst/" />
  <div class="outer-container">
    <img alt="start" src="../../assets/survey/beeldschermbril-evolutie.svg">
    <h1>Jouw mening maakt ons scherper.</h1>
    <p>Mogen wij je 10 korte vragen stellen over je beeldschermbril?</p>
    <div @click="startSurvey" class="button primary">Ja, stel maar</div>
    <p>3 minuten</p>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import NavBar from "@/components/NavBar";
const {mapActions} = createNamespacedHelpers("survey");

export default {
  name: "SurveyWelcome",
  components: {
    NavBar,
  },
  methods: {
    ...mapActions(["clearAnswers"]),
    startSurvey() {
      //this.clearAnswers(); // Commented out, but if needed clear answers.
      this.$router.push({
        name: 'SurveyBefore',
        params: {
          'opticienId': this.$route.params.opticienId,
          'companyId': this.$route.params.companyId
        }
      });
    }
  },
}
</script>

<style lang="scss" scoped>
  .outer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 8rem;

    h1 {
      margin: 4rem 0 2rem 0;
      padding: 0;
    }

    p {
      margin: 0;
      padding: 0;
    }

    .button {
      margin-top: 3.5rem;
      margin-bottom: 1rem;
    }
  }
</style>