<template>
  <NavBar :progress="100" titleSuffix="tevredenheidsonderzoek" customBase="vragenlijst/" />
  <div class="outer-container">
    <div class="thanks-title">
      <div class="thanks-check"></div>
      <h2>Bedankt voor je tijd.</h2>
    </div>
    <p>
      Wij verwerken alle antwoorden anoniem. Uiteraard hebben we meer mensen gevraagd naar<br/>
      hun mening. Alle antwoorden gebruiken wij om de beeldschermbril en onze service daar<br/>
      waar mogelijk te verbeteren.
    </p>
    <b class="optiek" style="color: #434343;">{{ getOpticienData()?.divisionData.name }}</b>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import {createNamespacedHelpers, mapGetters} from "vuex";
const {mapGetters: mapSurveyGetters, mapActions} = createNamespacedHelpers('survey');

export default {
  name: "SurveyThanks",
  components: {
    NavBar,
  },
  methods: {
    ...mapSurveyGetters(["getOtherGlasses", "getState"]),
    ...mapGetters(["getOpticienData"]),
    ...mapActions(["clearAnswers"])
  },
  created() {
    if (this.getOtherGlasses() === undefined) {
      this.$router.push({
        name: 'SurveyReview',
        params: {
          'opticienId': this.$route.params.opticienId,
          'companyId': this.$route.params.companyId
        }
      })
    } else {
      this.clearAnswers();
    }
  }
}
</script>

<style lang="scss" scoped>
  .outer-container {
    display: flex;
    padding-top: 8rem;
    gap: 2rem;

    h2 {
      margin: 0;
      padding: 0;
      color: #141E8C;
      font-size: 1.6rem;
    }

    p {
      margin: 0;
      padding: 0;
      line-height: 1.8rem;
    }

    .thanks-title {
      margin-left: 3rem;
      position: relative;

      .thanks-check {
        position: absolute;
        top: -0.5rem;
        left: -2.5rem;
        height: 1.6rem;
        width: 0.6rem;
        border-right: 0.25rem solid #141E8C;
        border-bottom: 0.25rem solid #141E8C;
        transform: rotate(45deg);
      }
    }
  }
</style>