<template>
  <NavBar :progress="12.5" />
  <div class="outer-container">
    <router-link :to="`/start/${$route.params.opticienId}/${$route.params.companyId}`">
      <img class="arrow-back" alt="arrow" src="../assets/arrow-left.svg">
    </router-link>
    <div class="container">
      <img alt="taart" src="../assets/beeldschermbril-taart.svg">
      <h2 class="content-title">Leeftijd</h2>
      <h3 class="content-subject">In welke leeftijdscategorie val jij?</h3>
      <div class="explanation-container">
        <div class="explanation-container-arrow" />
        <p>TOELICHTING</p>
        <span>Uit klantdata en wetenschappelijk onderzoek weten we dat leeftijd één van de factoren is om te bepalen of je profijt hebt van een beeldschermbril.</span>
      </div>
      <div class="ages-container">
        <div class="button primary" @click="updateResult('Jonger dan 25', false)">Jonger dan 25</div>
        <div class="button primary" @click="updateResult('25 - 35', false)">25 - 35</div>
        <div class="button primary" @click="updateResult('36 - 45', true)">36 - 45</div>
        <div class="button primary" @click="updateResult('45 of ouder', true)">45 of ouder</div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import { mapMutations } from 'vuex'

export default {
  name: 'Age',
  components: {
    NavBar,
  },
  methods: {
    ...mapMutations(['setAge']),
    updateResult(age, allowed) {
      this.setAge({age, allowed});
    },
  },
}
</script>

<style lang="scss" scoped>
  .container {
    margin-top: 8.5rem;
    > img {
      width: 10rem;
      margin-left: -13rem;
      margin-bottom: -9.3rem;
    }
  }

  .ages-container {
    margin-top: 7.7rem;
    display: flex;
    justify-content: space-between;
    .button {
      width: 7.2rem;
    }
  }
</style>
