<template>
  <NavBar :progress="20" titleSuffix="tevredenheidsonderzoek" customBase="vragenlijst/" />
  <div class="outer-container">
    <router-link :to="{ name: 'SurveyBefore', params: { 'opticienId': $route.params.opticienId, 'companyId': $route.params.companyId } }">
      <img class="arrow-back" alt="arrow" src="../../assets/arrow-left.svg">
    </router-link>
    <div class="question">
      <div class="content-title">
        2 / 10
      </div>
<!--      Has used other glasses before-->
      <scale-question :selected-value="getSatisfied()" @update:selectedValue="selectedValue" v-if="getUsedOther()">
        Hoe tevreden ben je met deze nieuwe<br/>
        beeldschermbril?
      </scale-question>
<!--      Not used other glasses before -->
      <scale-question :selected-value="getSatisfied()" @update:selectedValue="selectedValue" v-if="!getUsedOther()">
        Hoe tevreden ben je met jouw beeldschermbril?
      </scale-question>
    </div>
  </div>
</template>

<script>
  import NavBar from "@/components/NavBar";
  import ScaleQuestion from "@/components/ScaleQuestion";
  import {createNamespacedHelpers} from "vuex";
  const {mapGetters, mapMutations} = createNamespacedHelpers('survey');

  export default {
    name: "SurveyUsed",
    components: {
      ScaleQuestion,
      NavBar
    },
    methods: {
      ...mapGetters(["getUsedOther", "getSatisfied"]),
      ...mapMutations(["setSatisfied"]),
      selectedValue(newValue) {
        this.setSatisfied(newValue);
        this.$router.push(
          {
            name: 'SurveyWorkingPosition',
            params: {
              'opticienId': this.$route.params.opticienId,
              'companyId': this.$route.params.companyId
            }
          }
        );
      }
    },
    created() {
      // Navigate back when no previous answer found.
      if (this.getUsedOther() === undefined) this.$router.push(
          {
            name: 'SurveyBefore',
            params: {
              'opticienId': this.$route.params.opticienId,
              'companyId': this.$route.params.companyId
            }
          }
      );
    }
  }
</script>

<style scoped>
  .content-title {
    font-weight: bold;
  }

  .question {
    margin-top: 8rem;

    display: inline-flex;
    flex-direction: column;
    gap: 1.5rem;
  }
</style>