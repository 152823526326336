<template>
  <NavBar :progress="70" />
  <div class="outer-container">
    <router-link :to="`/zithouding/${$route.params.opticienId}/${$route.params.companyId}`">
      <img class="arrow-back" alt="arrow" src="../assets/arrow-left.svg">
    </router-link>
    <div class="container">
      <img alt="nek" src="../assets/beeldschermbril-nek.svg">
      <h2 class="content-title">Klachten</h2>
      <h3 class="content-subject">Heb je nek-, rug- of schouderklachten?</h3>
      <div class="explanation-container">
        <div class="explanation-container-arrow" />
        <p>TOELICHTING</p>
        <span>Als je je houding aan een beeldscherm hebt aangepast omdat je anders niet scherp ziet, dan is de kans groot dat je klachten hebt, of gaat ontwikkelen. Je merkt dat vaak pas aan het einde van een werkdag. Hoe langer je de klachten negeert hoe vaker en langer ze op gaan treden.</span>
      </div>
      <div class="options-container">
        <div class="button primary" @click="updateResult(true)">Ja</div>
        <div class="pipe" />
        <div class="button secondary" @click="updateResult(false)">Nee</div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import { mapMutations } from 'vuex';

export default {
  name: 'Complaints',
  components: {
    NavBar,
  },
  methods: {
    ...mapMutations(['setComplaints']),
    updateResult(boolean) {
      this.setComplaints(boolean);
    }
  },
}
</script>

<style lang="scss" scoped>
  .container {
    margin-top: 8.5rem;
    > img {
      width: 8.3rem;
      margin-left: -12rem;
      margin-bottom: -10.8rem;
    }
  }
</style>
