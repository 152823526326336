<template>
  <NavBar :progress="100" />
  <div class="outer-container">
    <router-link :to="`/advies/${$route.params.opticienId}/${$route.params.companyId}`">
      <img class="arrow-back" alt="arrow" src="../assets/arrow-left.svg">
    </router-link>
    <div class="form-container">
      <h2 class="content-title">Jouw gegevens</h2>
      <div class="form">
        <div class="input-element">
          <label for="first-name">Voornaam</label>
          <input v-model="firstName" type="text" id="first-name">
        </div>
        <div class="input-element">
          <label for="prefix">tussenvoegsel</label>
          <input v-model="prefix" type="text" id="prefix">
        </div>
        <div class="input-element">
          <label for="last-name">Achternaam</label>
          <input v-model="lastName" type="text" id="last-name">
        </div>
        <div class="input-element question-mark">
          <label for="email">E-mailadres (zakelijk)</label>
          <input v-model="email" type="email" id="email">
          <img alt="question" src="../assets/question.svg" @mouseenter="information = true" @mouseleave="information = false">
          <div class="information-screen" v-if="information">
            Geef hier je zakelijke e-mailadres op. Dit adres <br> gebruiken wij bij akoord om contact met jou en je<br> werkgever te kunnen onderhouden.
          </div>
        </div>
        <div class="input-element remark">
          <label for="remark">Opmerking</label>
          <textarea v-model="remark" id="remark" />
        </div>
        <h6>{{ error }}</h6>
      </div>

      <div class="send-or-cancel">
        <div class="button primary" @click="submitForm()">Verstuur</div>
        <div class="button secondary" @click="clearState()">Cancel</div>
      </div>
      <p>Een HR-Medewerker zal je aanvraag beoordelen. Zodra je aanvraag is goedgekeurd, mag je bij {{ (getCheckIfData) ? getOpticienData.divisionData.name : 'ons' }} een afspraak maken voor een oogmeting. 
        Je werkgever vergoedt dan de kosten voor het aanmeten en de aanschaf van een beeldschermbril. 
        Je krijgt de goedkeuring (of afwijzing) per e-mail van je HR-Manager.
      </p>
      <h5>
        Door deelname aan deze Beeldschermbril actie ga je akkoord met de verwerking van jouw persoonsgegevens ten behoeve van het uitvoeren van deze overeenkomst door opticien en haar subverwerkers.
      </h5>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'CredentialsForm',
  components: {
    NavBar,
  },
  data() {
    return {
      information: false,
      firstName: undefined,
      prefix: '',
      lastName: undefined,
      email: undefined,
      remark: '',
      error: '',
    }
  },
  computed: {
    ...mapGetters(['getCheckIfData', 'getOpticienData']),
  },
  watch: {
    error() {
      setTimeout(() => this.error = '', 3000);
    },
  },
  methods: {
    ...mapActions(['sendData']),
    ...mapMutations(['setCredentials','clearState']),
    async submitForm() {
      if (!this.email) {
        this.error = 'Voer je e-mailadres in!';
      }

      if (!this.lastName) { 
        this.error = 'Voer je achternaam in!';
      }

      if (!this.firstName) {
        this.error = 'Voer je voornaam in!';
      }

      if (this.firstName && this.lastName && this.email) {
        await this.setCredentials({
          firstName: this.firstName,
          prefix: this.prefix,
          lastName: this.lastName,
          email: this.email,
          remark: this.remark, 
        });
        this.sendData();

        // clear local state
        this.firstName = undefined;
        this.prefix = '';
        this.lastName = undefined;
        this.email = undefined;
        this.remark = '';
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  .form-container {
    margin-top: 2.5rem;
    p {
      margin-top: 2rem;
      font-size: 0.88rem;
      line-height: 1.7rem;
      color: #3d3d3d;
    }
    .form {
      margin-top: 3rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      h6 {
        color: red;
        font-size: 1rem;
        font-weight: 400;
        margin-top: -1rem;
      }
      .input-element {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.7rem;
        label {
          font-size: 0.7rem;
          color: #6d6d6d;
          margin-bottom: 0.45rem;
          margin-left: 0.05rem;
        }
        input {
          font-size: 0.9rem;
          color: #424242;
          background-color: #fafafa;
          border-radius: 0.3rem;
          border: 0.08rem solid #e4e4e4;
          padding: 0.9rem 0.8rem 0.9rem 0.8rem;
        }
        #first-name {
          width: 13rem;
        }
        #prefix {
          width: 5rem;
        }
        #last-name {
          width: 19rem;
        }
      }
      .question-mark {
        width: 100%;
        img {
          width: 0.75rem;
          margin-left: 7.2rem;
          margin-top: -4.2rem;
        }
        .information-screen {
          width: fit-content;
          padding: 0.8rem;
          margin-left: 8rem;
          margin-top: -5.5rem;
          margin-bottom: 1.4rem;
          background-color: #f8f8f8;
          line-height: 1.1rem;
          box-shadow: 0rem 0.1rem 0.7rem 0.2rem #b9b9b954;
          font-size: 0.7rem;
          color: #535353;
        }
      }
      .remark {
        margin-top: 3.4rem;
        margin-bottom: 2.5rem;
        width: 100%;
        textarea {
          font-family: 'Roboto', sans-serif;
          height: 5rem;
          font-size: 0.9rem;
          color: #424242;
          background-color: #fafafa;
          border-radius: 0.3rem;
          border: 0.08rem solid #e4e4e4;
          padding: 0.9rem 0.8rem 0.9rem 0.8rem;
        }
      }
    }
    .send-or-cancel {
      display: flex;
      width: 23.5rem;
      justify-content: space-between;
      .primary {
        width: 9.4rem;
      }
      .secondary {
        width: 5.5rem;
      }
    }
  }
</style>
