<template>
  <NavBar :progress="80" titleSuffix="tevredenheidsonderzoek" customBase="vragenlijst/" />
  <div class="outer-container">
    <router-link :to="{ name: 'SurveyService', params: { 'opticienId': $route.params.opticienId, 'companyId': $route.params.companyId } }">
      <img class="arrow-back" alt="arrow" src="../../assets/arrow-left.svg">
    </router-link>
    <div class="question">
      <div class="content-title">
        8 / 10
      </div>
      <scale-question
        :selected-value="getRecommend()"
        @update:selectedValue="selectedValue"
        lowQuote="Nee nooit"
        highQuote="Ja zeer zeker"
      >
        Zou je jouw vrienden of familie deze<br/>
        beeldschermbril aanraden als ze veel op een<br/>
        beeldscherm werken?
      </scale-question>
    </div>
  </div>
</template>

<script>
  import NavBar from "@/components/NavBar";
  import ScaleQuestion from "@/components/ScaleQuestion";
  import {createNamespacedHelpers} from "vuex";
  const {mapGetters, mapMutations} = createNamespacedHelpers('survey');

  export default {
    name: "SurveyRecommend",
    components: {
      ScaleQuestion,
      NavBar
    },
    methods: {
      ...mapGetters(["getService", "getRecommend"]),
      ...mapMutations(["setRecommend"]),
      selectedValue(newValue) {
        this.setRecommend(newValue);
        this.$router.push(
          {
            name: 'SurveyOtherGlasses',
            params: {
              'opticienId': this.$route.params.opticienId,
              'companyId': this.$route.params.companyId
            }
          }
        );
      }
    },
    created() {
      // Navigate back when no previous answer found.
      if (this.getService() === undefined) this.$router.push(
          {
            name: 'SurveyService',
            params: {
              'opticienId': this.$route.params.opticienId,
              'companyId': this.$route.params.companyId
            }
          }
      );
    }
  }
</script>

<style scoped>
  .content-title {
    font-weight: bold;
  }

  .question {
    margin-top: 8rem;

    display: inline-flex;
    flex-direction: column;
    gap: 1.5rem;
  }
</style>