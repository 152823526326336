<template>
  <NavBar :progress="10" titleSuffix="tevredenheidsonderzoek" customBase="vragenlijst/" />
  <div class="outer-container">
    <router-link :to="{ name: 'SurveyWelcome', params: { 'opticienId': $route.params.opticienId, 'companyId': $route.params.companyId } }">
      <img class="arrow-back" alt="arrow" src="../../assets/arrow-left.svg">
    </router-link>

    <div class="question">
      <div class="content-title">
        1 / 10
      </div>

      <h3 class="content-subject">
        Gebruikte je voor de beeldschermbril met ZEISS<br/>
        brillenglazen al een ander merk beeldschermbril?
      </h3>

      <div style="display: flex; flex-direction: row; gap: 2rem;">
        <RadioSelector @update:selected="setUsedOther($event)" :selected="getUsedOther()">Ja</RadioSelector>
        <RadioSelector @update:selected="setUsedOther(!$event)" :selected="(getUsedOther() != undefined ? !getUsedOther() : false)">Nee</RadioSelector>
      </div>

      <div :class="['button', 'primary', {'disabled': getUsedOther() === undefined}]" @click="goNextQuestion">Ga verder</div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import RadioSelector from "@/components/RadioSelector";
import {createNamespacedHelpers} from "vuex";
const {mapGetters, mapMutations} = createNamespacedHelpers('survey');

export default {
  name: "Before",
  components: {
    RadioSelector,
    NavBar
  },
  methods: {
    ...mapMutations(["setUsedOther"]),
    ...mapGetters(["getUsedOther"]),
    goNextQuestion() {
      if (this.getUsedOther() !== undefined) {
        this.$router.push({
          name: 'SurveySatisfied',
          params: {
            'opticienId': this.$route.params.opticienId,
            'companyId': this.$route.params.companyId
          }
        });
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  .content-title {
    font-weight: bold;
  }

  .question {
    margin-top: 8rem;

    display: inline-flex;
    flex-direction: column;
    gap: 1.5rem;

    h3 {
      margin: 0;
    }
  }
</style>