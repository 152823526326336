<template>
  <NavBar :progress="70" titleSuffix="tevredenheidsonderzoek" customBase="vragenlijst/" />
  <div class="outer-container">
    <router-link :to="{ name: 'SurveyLessPain', params: { 'opticienId': $route.params.opticienId, 'companyId': $route.params.companyId } }">
      <img class="arrow-back" alt="arrow" src="../../assets/arrow-left.svg">
    </router-link>
    <div class="question">
      <div class="content-title">
        7 / 10
      </div>
      <scale-question
        :selected-value="getService()"
        @update:selectedValue="selectedValue"
        lowQuote="Zeer ontevreden"
        highQuote="Zeer tevreden"
      >
        Hoe tevreden ben je over jouw bezoek en onze<br/>
        service in de winkel?
      </scale-question>
    </div>
  </div>
</template>

<script>
  import NavBar from "@/components/NavBar";
  import ScaleQuestion from "@/components/ScaleQuestion";
  import {createNamespacedHelpers} from "vuex";
  const {mapGetters, mapMutations} = createNamespacedHelpers('survey');

  export default {
    name: "SurveyService",
    components: {
      ScaleQuestion,
      NavBar
    },
    methods: {
      ...mapGetters(["getUsedOther", "getLessPain", "getService"]),
      ...mapMutations(["setService"]),
      selectedValue(newValue) {
        this.setService(newValue);
        this.$router.push(
          {
            name: 'SurveyRecommend',
            params: {
              'opticienId': this.$route.params.opticienId,
              'companyId': this.$route.params.companyId
            }
          }
        );
      }
    },
    created() {
      // Navigate back when no previous answer found.
      if (this.getLessPain() === undefined) this.$router.push(
          {
            name: 'SurveyLessPain',
            params: {
              'opticienId': this.$route.params.opticienId,
              'companyId': this.$route.params.companyId
            }
          }
      );
    }
  }
</script>

<style scoped>
  .content-title {
    font-weight: bold;
  }

  .question {
    margin-top: 8rem;

    display: inline-flex;
    flex-direction: column;
    gap: 1.5rem;
  }
</style>