<template>
  <div class="scaleQuestion">
    <h3 class="content-subject"><slot /></h3>
    <div class="options">
      <div class="scale">
        <scale-option @update:selected="selectedAnswer" :selected="(selectedValue === 1)" color="#E80D0D" :value="1" />
        <scale-option @update:selected="selectedAnswer" :selected="(selectedValue === 2)" color="#ED410E" :value="2" />
        <scale-option @update:selected="selectedAnswer" :selected="(selectedValue === 3)" color="#FE6442" :value="3" />
        <scale-option @update:selected="selectedAnswer" :selected="(selectedValue === 4)" color="#FFA62A" :value="4" />
        <scale-option @update:selected="selectedAnswer" :selected="(selectedValue === 5)" color="#FFC125" :value="5" />
        <scale-option @update:selected="selectedAnswer" :selected="(selectedValue === 6)" color="#E3C424" :value="6" />
        <scale-option @update:selected="selectedAnswer" :selected="(selectedValue === 7)" color="#C0C924" :value="7" />
        <scale-option @update:selected="selectedAnswer" :selected="(selectedValue === 8)" color="#9DCB23" :value="8" />
        <scale-option @update:selected="selectedAnswer" :selected="(selectedValue === 9)" color="#79CA21" :value="9" />
        <scale-option @update:selected="selectedAnswer" :selected="(selectedValue === 10)" color="#53C70A" :value="10" />
      </div>
      <div class="scale-suffix">
        <p>1. <span v-html="lowQuote"/></p>
        <p style="text-align: right;">10. <span v-html="highQuote"/></p>
      </div>
    </div>
  </div>
</template>

<script>
import ScaleOption from "@/components/ScaleOption";
export default {
  name: 'ScaleQuestion',
  components: {ScaleOption},
  emits: ['update:selectedValue'],
  props: {
    selectedValue: {
      type: Number,
    },
    lowQuote: {
      type: String,
      default: "Zeer ontevreden"
    },
    highQuote: {
      type: String,
      default: 'Zeer tevreden'
    }
  },
  methods: {
    selectedAnswer(value) {
      this.$emit('update:selectedValue', value);
    },
  },
}
</script>

<style lang="scss" scoped>
  .scaleQuestion {
    .content-subject {
      font-size: 2rem;
      font-weight: 400;
      line-height: 2.5rem;
      margin-top: 0;
      margin-bottom: 2.4rem;
    }

    .options {
      display: inline-flex;
      flex-direction: column;
      gap: 1rem;

      .scale {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        flex-wrap: wrap;
      }

      .scale-suffix {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #8B8D8E;

        p {
          margin: 0;
          padding: 0;
          max-width: 10rem;
        }
      }
    }
  }
</style>
