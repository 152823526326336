<template>
  <div />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'InsertFromEmail',
  created() {
    this.sendDataFromUrl();
  },
  methods: {
    ...mapActions(['sendEmailData']),
    sendDataFromUrl() {
      this.sendEmailData(this.$route.query);
    },
  },
}
</script>
