<template>
  <div>
    <h1>
      Bedankt voor uw bezoek.<br> We verwijzen u graag naar het bericht van uw werkgever,<br> volg de link in de mail om de aanvraag te voltooien.
    </h1>
  </div>
</template>

<script>
export default {
  name: 'Reference',
}
</script>

<style lang="scss" scoped>
  div {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h1 {
    margin: 3rem;
    font-size: 2.5rem;
    color: #141e8c;
    font-weight: 400;
  }
</style>
