<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
  
  body {
    height: 100%;
    margin: 0;
  }

  #app {
    font-family: 'Frutiger', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @font-face {
    font-family: "Frutiger";
    font-weight: 300;
    font-style: normal;
    font-display: auto;
    unicode-range: U+000-5FF;
    src: local("Frutiger"), url("./assets/fonts/ZEISSFrutigerNextW1G-Light.woff") format("woff");
  }

  @font-face {
    font-family: "Frutiger";
    font-weight: 400;
    font-style: normal;
    font-display: auto;
    unicode-range: U+000-5FF;
    src: local("Frutiger"), url("./assets/fonts/ZEISSFrutigerNextW1G-Reg.woff") format("woff");
  }

  @font-face {
    font-family: "Frutiger";
    font-weight: 500;
    font-style: normal;
    font-display: auto;
    unicode-range: U+000-5FF;
    src: local("Frutiger"), url("./assets/fonts/ZEISSFrutigerNextW1G-Medium.woff") format("woff");
  }

  @font-face {
    font-family: "Frutiger";
    font-weight: 600;
    font-style: normal;
    font-display: auto;
    unicode-range: U+000-5FF;
    src: local("Frutiger"), url("./assets/fonts/ZEISSFrutigerNextW1G-Bold.woff") format("woff");
  }

  .outer-container {
    width: 44rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5rem;
    .arrow-back {
      width: 2rem;
      position: absolute;
      top: 11rem;
      left: 10rem;
    }
  }
  
  .button {
    padding: 0.8rem 1.55rem 0.8rem 1.55rem;
    font-size: 1.1rem;
    font-weight: 500;
    text-align: center;
    cursor: pointer;

    // Transition for smooth change of disabled/enabled state
    transition: all 0.25s ease-in-out;
    // Prevent text-selection in button
    user-select: none;
  }

  .primary {
    color: #ffffff;
    border: 0.09rem solid #008BD0;
    background-color: #008BD0;
    width: fit-content;
    &:hover {
      border: 0.09rem solid #0096e0;
      background-color: #0096e0;
    }
  }

  .secondary {
    color: #008BD0;
    border: 0.09rem solid #E0E1DD;
    width: fit-content;
    &:hover {
      background-color: #f0f6fc;
    }
  }

  // Disabled state for buttons
  .disabled,
  .disabled:hover {
    width: fit-content;
    color: #888;
    border: 0.09rem solid #EEEEEE;
    background-color: #EEEEEE;
    cursor: not-allowed;
  }

  .content-title {
    font-size: 1.35rem;
    color: #141e8c;
    margin: 0;
    margin-bottom: -0.5rem;
  }

  .content-subject {
    font-size: 1.65rem;
    font-weight: 400;
    line-height: 2.1rem;
    margin-bottom: 2.4rem;
  }

  .explanation-container {
    height: fit-content;
    padding: 1.5rem;
    background-color: #f8f8f8;
    color: #535353;
    p {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0.1rem;
    }
    span {
      font-size: 1rem;
      line-height: 1.7rem;
    }
  }

  .explanation-container-arrow {
    background-color: #f8f8f8;
    width: 0.8rem;
    height: 0.8rem;
    margin-top: -2rem;
    margin-left: 0.3rem;
    transform: rotate(45deg);
  }

  .options-container {
    width: 23.5rem;
    margin-top: 3.7rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    .button {
      width: 5.6rem;
    }
    .pipe {
      width: 0.05rem;
      background-color: #e5e5e5;
    }
  }

</style>
