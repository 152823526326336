<template>
  <div>
    <b class="button primary">Goedgekeurd</b>
    <h1>
      Je hebt de aanvraag goedgekeurd,<br> de medewerker ontvangt hierover bericht.
    </h1>
  </div>
</template>

<script>
export default {
  name: 'RequestAccepted',
}
</script>

<style lang="scss" scoped>
  div {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  h1 {
    margin: 3rem;
    font-size: 2.5rem;
    color: #141e8c;
    font-weight: 400;
  }
  .button {
    cursor: default;
  }
  .primary {
    background-color: #53c70a;
  }
</style>
