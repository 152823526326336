<template>
  <NavBar :progress="37.5" />
  <div class="outer-container">
    <router-link :to="`/schermtijd/${$route.params.opticienId}/${$route.params.companyId}`">
      <img class="arrow-back" alt="arrow" src="../assets/arrow-left.svg">
    </router-link>
    <div class="container">
      <img alt="brilman" src="../assets/beeldschermbril-hoofd.svg">
      <h2 class="content-title">Bril of/en lenzen</h2>
      <h3 class="content-subject">Draag je al een (lees-)bril, multifocale bril of<br> contactlenzen op je werk of privé?</h3>
      <div class="explanation-container">
        <div class="explanation-container-arrow" />
        <p>TOELICHTING</p>
        <span>Elke bril of elk paar lenzen is op maat gemaakt om optimaal zicht op een bepaalde afstand te bieden. Een beeldschermbril is geoptimaliseerd voor die kenmerkende 50 cm kijkafstand. Een leesbril, de meeste multifocale en enkelvoudige brillen zijn dat niet.</span>
      </div>
      <div class="options-container">
        <div class="button primary" @click="updateResult(true)">Ja</div>
        <div class="pipe" />
        <div class="button secondary" @click="updateResult(false)">Nee</div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import { mapMutations } from 'vuex'

export default {
  name: 'GlassesLenses',
  components: {
    NavBar,
  },
  methods: {
    ...mapMutations(['setGlassesAndOrLenses']),
    updateResult(boolean) {
      this.setGlassesAndOrLenses(boolean);
    },
  },
}
</script>

<style lang="scss" scoped>
  .container {
    margin-top: 8.5rem;
    > img {
      width: 8rem;
      margin-left: -11.5rem;
      margin-bottom: -9.4rem;
    }
  }
</style>
