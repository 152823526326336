<template>
  <div :class="['radioSelector', {'active': selected}]" @click="$emit('update:selected', true)">
    <div class="selectBox"></div>
    <span><slot /></span>
  </div>
</template>

<script>
export default {
  name: "RadioSelector",
  props: {
    selected: {
      type: [Boolean, undefined]
    }
  }
}
</script>

<style lang="scss" scoped>
  $radioSize: 1.5rem;

  .radioSelector {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    font-size: 1.2rem;

    .selectBox {
      height: $radioSize;
      width: $radioSize;
      border-radius: 50%;
      background: transparent;
      border: 1px solid #8C8E8F;

      display: inline-flex;
      justify-content: center;
      align-items: center;

      &::after {
        transition: all .25s ease-in-out;
        content: '';
        display: block;
        background: #008BD0;
        border-radius: 50%;
        height: calc(#{$radioSize} - calc(2px + calc(#{$radioSize} / 2.5)));
        width: calc(#{$radioSize} - calc(2px + calc(#{$radioSize} / 2.5)));
        opacity: 0;
      }
    }

    &:hover {
      .selectBox::after {
        opacity: 0.5;
      }
    }

    &.active {
      .selectBox::after {
        opacity: 1;
      }
    }
  }
</style>