<template>
  <div class="nav-container" v-if="getCheckIfData">
    <nav class="nav-bar">
      <h1 @click="navigateTo(getHeaderUrl)">Beeldschermbril <span>{{ titleSuffix }}</span></h1>
      <router-link :to="getHeaderUrl">
        <img alt="logo" :src="`http://zeissapi.leadq.nl/Resources/Image/${getOpticienData.divisionData.logo}`">
      </router-link>
    </nav>
    <div class="progress-bar" :style="{ width: `${progress}%` }" />
  </div>
  <div class="contact" v-if="getCheckIfData">
    <p>{{ `${getOpticienData.divisionData.address} &nbsp;&nbsp;|&nbsp;&nbsp; ${getOpticienData.divisionData.zipcode} ${getOpticienData.divisionData.place} &nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;` }}<span>{{ `${getOpticienData.divisionData.phone} &nbsp;&nbsp;|&nbsp;&nbsp; ${getOpticienData.divisionData.email}` }}</span></p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'NavBar',
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    titleSuffix: {
      type: String,
      default: 'wijzer'
    },
    customBase: {
      type: String,
      default: undefined,
    },
  },
  created() {
    const data = {
      opticienId: this.$route.params.opticienId,
      companyId: this.$route.params.companyId,
    }
    this.fetchOpticienData(data);
  },
  computed: {
    ...mapGetters(['getCheckIfData', 'getOpticienData']),
    getHeaderUrl: {
      get() {
        return `/${( this.customBase ? this.customBase : 'start/' )}${this.$route.params.opticienId}/${this.$route.params.companyId}`;
      }
    }
  },
  methods: {
    ...mapActions(['fetchOpticienData']),
    navigateTo(path) {
      this.$router.push(path);
    }
  }
}
</script>

<style lang="scss" scoped>
  .nav-container {
    width: 87%;
    margin: auto;
  }

  .nav-bar {
    height: 8.5rem;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 3rem;
    padding-right: 2rem;
    border-bottom: 0.15rem solid #f0f0f0;
    h1 {
      font-size: 2rem;
      color: #000000;
      margin-top: 2.7rem;
      cursor: pointer;
      span {
        color: #111111;
        font-weight: 300;
      }
    }
    img {
      width: 15rem;
    }
  }

  .progress-bar {
    height: 0.29rem;
    background-color: #141e8c;
  }

  .contact {
    position: fixed;
    background-color: #ffffff;
    bottom: 0.9rem;
    right: 10rem;
    p {
      font-size: 0.77rem;
      font-weight: 500;
      color: #494949;
      span {
        color: #494949;
      }
    }
  }
</style>
