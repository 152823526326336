<template>
  <NavBar :progress="40" titleSuffix="tevredenheidsonderzoek" customBase="vragenlijst/" />
  <div class="outer-container">
    <router-link :to="{ name: 'SurveyWorkingPosition', params: { 'opticienId': $route.params.opticienId, 'companyId': $route.params.companyId } }">
      <img class="arrow-back" alt="arrow" src="../../assets/arrow-left.svg">
    </router-link>
    <div class="question">
      <div class="content-title">
        4 / 10
      </div>
      <!--      Has used other glasses before-->
      <scale-question
        v-if="getUsedOther()"
        :selected-value="getTiredEyes()"
        @update:selectedValue="selectedValue"
        lowQuote="Ik merk geen verschil"
        highQuote="Ja, veel minder last <i>met</i> mijn nieuwe beeldschermbril."
      >
        Heb je <i>met</i> je nieuwe beeldschermbril minder<br/>
        last van vermoeide ogen na langere werksessies<br/>
        op een beeldscherm dan <i>met je andere</i><br/>
        <i>beeldschermbril?</i>
      </scale-question>
<!--      Not used other glasses before -->
      <scale-question
        v-if="!getUsedOther()"
        :selected-value="getTiredEyes()"
        @update:selectedValue="selectedValue"
        lowQuote="Ik merk geen verschil"
        highQuote="Ja, veel minder last <i>met</i> mijn nieuwe beeldschermbril."
      >
        In hoeverre heb je minder last van vermoeide<br/>
        ogen na langere werksessies achter een<br/>
        beeldscher met je beeldschermbril?
      </scale-question>
    </div>
  </div>
</template>

<script>
  import NavBar from "@/components/NavBar";
  import ScaleQuestion from "@/components/ScaleQuestion";
  import {createNamespacedHelpers} from "vuex";
  const {mapGetters, mapMutations} = createNamespacedHelpers('survey');

  export default {
    name: "TiredEyes",
    components: {
      ScaleQuestion,
      NavBar
    },
    data() {
      return {
        currentValue: undefined,
      }
    },
    methods: {
      ...mapGetters(["getUsedOther", "getWorkingPosition", "getTiredEyes"]),
      ...mapMutations(["setTiredEyes"]),
      selectedValue(newValue) {
        this.setTiredEyes(newValue);
        this.$router.push(
          {
            name: 'SurveySharperVision',
            params: {
              'opticienId': this.$route.params.opticienId,
              'companyId': this.$route.params.companyId
            }
          }
        );
      }
    },
    created() {
      // Navigate back when no previous answer found.
      if (this.getWorkingPosition() === undefined) this.$router.push(
          {
            name: 'SurveyWorkingPosition',
            params: {
              'opticienId': this.$route.params.opticienId,
              'companyId': this.$route.params.companyId
            }
          }
      );
    }
  }
</script>

<style scoped>
  .content-title {
    font-weight: bold;
  }

  .question {
    margin-top: 8rem;

    display: inline-flex;
    flex-direction: column;
    gap: 1.5rem;
  }
</style>