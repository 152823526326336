<template>
  <NavBar :progress="0" />
  <div class="outer-container">
    <img alt="start" src="../assets/beeldschermbril-evolutie.svg">
    <h1>Zou jij in jouw werk profijt hebben van een beeldschermbril?</h1>
    <router-link :to="`/leeftijd/${$route.params.opticienId}/${$route.params.companyId}`">
      <div class="button primary">Start de Beeldschermbril-wijzer</div>
    </router-link>
    <p>Jouw HR-Manager bekijkt de antwoorden (in grote lijnen). Op basis van je antwoorden stuurt de HR-Manager jou wel of niet door voor een oogmeting.</p>
    <h5 @click="modal = true">Waarom een beeldschermbril?</h5>
    <div v-if="modal" class="modal">
      <div class="close" @click="modal = false">
        <img alt="x" src="../assets/x.svg"> Sluiten
      </div>
      <div class="modal-top">
        <h1>Een beeldschermbril werkt</h1>
        <h2>Natuurlijk rechtop</h2>
        <p>
          Als je lekker in je vel zit, dan is werken leuk, makkelijk en blijf je productief. 
          Uit onderzoek blijkt dat een beeldschermbril een verantwoord en verstandig hulpmiddel is voor iedereen die achter beeldschermen (laptop, pc, tablet, smartphone) werkt.
          Een op maat gemaakte beeldschermbril corrigeert de houding heel natuurlijk. 
          Zo blijft je blik heel ontspannen, heb je aan het einde van de dag minder vermoeide ogen en voorkom je nek en rugklachten.<br><br>
          Daarom biedt {{ (getCheckIfData) ? getOpticienData.companyData.name : 'je werkgever' }} al haar medewerkers die daar baat bij hebben de mogelijkheid om een beeldschermbril uit te zoeken. 
          De kosten voor het aanmeten en de aanschaf vergoedt {{ (getCheckIfData) ? getOpticienData.companyData.name : 'je werkgever' }}.
        </p>
      </div>
      <div class="info">
        <img src="../assets/aanbod.svg" alt="Aanbod">
      </div>
      <div class="modal-mid">
        <img src="../assets/zo-werkt-deze-bril.svg" alt="Zo werkt deze bril">
        <div>
          <h2>Samen met de zelfstandige opticien</h2>
          <p>
            {{ (getCheckIfData) ? getOpticienData.companyData.name : 'je werkgever' }} werkt voor het advies en aanmeten van een beeldschermbril samen met de zelfstandige vakopticien {{ (getCheckIfData) ? getOpticienData.divisionData.name : 'je opticien' }} en brillenfabrikant ZEISS. 
            De optometrist van deze opticien beoordeelt alle aanvragen van medewerkers van {{ (getCheckIfData) ? getOpticienData.companyData.name : 'je werkgever' }}. Zij meten je ogen en kijken of jij profijt hebt bij een beeldschermbril.
          </p>
        </div>
        <img src="../assets/juist-voor-brildragers1.svg" alt="Zo werkt deze bril">
      </div>
      <div class="modal-bottom">
        <h2>Voordelen van een beeldschermbril</h2>
        <p>Ook als je al een bril of contactlenzen draagt.</p>
        <div>
          <div>
            <img src="../assets/check-blue.svg" alt="vinkje"><p>Minder vermoeide ogen</p>
          </div>
          <div>
            <img src="../assets/check-blue.svg" alt="vinkje"><p>Minder nek- en rugklachten</p>
          </div>
          <div>
            <img src="../assets/check-blue.svg" alt="vinkje"><p>Helder zicht op alle werkafstanden</p>
          </div>
          <div>
            <img src="../assets/check-blue.svg" alt="vinkje"><p>Service bij een zelfstandige opticien</p>
          </div>
        </div>
        <h3>Kosten</h3>
        <p>
          {{ (getCheckIfData) ? getOpticienData.companyData.name : 'je werkgever' }} betaalt de kosten voor het aanmeten en de aanschaf van de beeldschermbril. 
          De opticien<br> biedt jou de keuze uit een aantal monturen. Wil je een montuur buiten deze, dan betaal je alleen de meerprijs.<br><br>
        </p>
        <p>
          Heb je vragen over de beeldschermbril, de vergoeding of deze regeling? Neem dan contact op met<br><br>
        </p>
        <p>
          {{ (getCheckIfData) ? getOpticienData.companyData.managerFirstName : '' }} {{ (getCheckIfData) ? getOpticienData.companyData.managerLastName : '' }}<br>
          {{ (getCheckIfData) ? getOpticienData.companyData.name : 'je werkgever' }}<br>
          {{ (getCheckIfData) ? getOpticienData.companyData.managerEmail : '' }}<br>
          {{ (getCheckIfData) ? getOpticienData.companyData.managerPhone : '' }}
        </p>
      </div>
    </div> 
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import { mapGetters } from 'vuex';

export default {
  name: 'Start',
  components: {
    NavBar,
  },
  data() {
    return {
      modal: false,
    };
  },
  computed: {
    ...mapGetters(['getCheckIfData', 'getOpticienData']),
  },
}
</script>

<style lang="scss" scoped>
  .outer-container {
    align-items: center;
    > img {
      margin-top: 6.1rem;
      width: 46rem;
    }
  }

  h1 {
    font-size: 1.9rem;
    font-weight: 600;
    color: #000000;
    width: 33rem;
    text-align: center;
    margin-top: 2.7rem;
    margin-bottom: 2rem;
  }

  p {
    width: 33rem;
    margin-top: 3.8rem;
    font-size: 0.87rem;
    line-height: 1.8rem;
    text-align: center;
    color: #3d3d3d;
  }

  h5 {
    margin-top: 2.1rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 300;
    color: #0091da;
    border-bottom: 0.06rem solid #0091da;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .modal {
    position: absolute;
    top: 2rem;
    width: 70%;
    height: auto;
    margin-bottom: 2rem;
    background-color: #ffffff;
    box-shadow: 0rem 0rem 17rem 17rem #a5a5a5ee;
    .close {
      position: relative;
      z-index: 10;
      margin: 2rem;
      display: flex;
      cursor: pointer;
      width: 5rem;
      align-items: center;
      justify-content: space-between;
      float: right;
      color: #008bd0;
    }
    .modal-top {
      width: fit-content;
      padding: 5rem 10rem 6rem 10rem;
      position: relative;
      display: block;
      h1 {
        width: fit-content;
        font-size: 2.5rem;
        color: #313131;
        font-weight: 600;
      }
      h2 {
        font-size: 1.5rem;
        font-weight: 400;
        color: #000000;
      }
      p {
        font-size: 1rem;
        color: #414141;
        width: 45rem;
        margin-top: 0rem;
        text-align: left;
      }
    }
    .info {
      margin-top: -5rem;
      padding: 0rem 4rem 5rem 4rem;
      position: relative;
      img {
        width: 100%;
      }
    }
    .modal-mid {
      position: relative;
      margin-top: -3.1rem;
      img {
        width: 100%;
      }
      div {
        padding: 3.2rem 10rem 4rem 10rem;
        h2 {
          font-size: 1.5rem;
          font-weight: 400;
          color: #000000;
        }
        p {
          font-size: 1rem;
          color: #414141;
          width: 36rem;
          margin-top: 0rem;
          text-align: left;
        }
      }
    }
    .modal-bottom {
      padding: 3.2rem 10rem 4rem 10rem;
      margin-bottom: 3rem;
      h2 {
        font-size: 1.9rem;
        font-weight: 600;
        color: #000000;
      }
      h3 {
        margin-top: 5rem;
        font-size: 1.5rem;
        font-weight: 600;
        color: #000000;
      }
      p {
        font-size: 1rem;
        color: #414141;
        width: 100%;
        margin-top: 0rem;
        text-align: left;
      }
      div {
        display: flex;
        flex-direction: column;
        div {
          display: -webkit-inline-box;
          height: 2.3rem;
          img {
            width: 1.7rem;
          }
          p {
            margin-left: 1rem;
            width: fit-content;
          }
        }
      }
    }
  }
</style>
