<template>
  <div class="multipleChoiceOption" @click="selectOption">
    <div :class="['selectBox', {'selected': selected}]"></div>
    <p>{{ option.label }}</p>
  </div>
</template>

<script>
export default {
  name: "MultipleChoiceOption",
  emits: [
      "selected:value"
  ],
  props: {
    option: {
      type: Object
    },
    selected: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    selectOption() {
      this.$emit("selected:value", this.option.value);
    }
  }
}
</script>

<style lang="scss" scoped>
  $selectBoxSize: 1.6rem;

  .multipleChoiceOption {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;

    p {
      margin: 0;
    }

    .selectBox {
      height: $selectBoxSize;
      width: $selectBoxSize;
      border: 1px solid #8B8D8E;
      border-radius: 0.3rem;
      display: flex;
      justify-content: center;

      &::after {
        margin-top: 0.1rem;
        transition: all 0.25s ease-in-out;
        content: '';
        display: block;
        border-right: 0.2rem solid #008BD0;
        border-bottom: 0.2rem solid #008BD0;
        transform: rotateZ(38deg);
        height: #{$selectBoxSize * 0.6};
        width: #{$selectBoxSize * 0.22};
        opacity: 0;
      }

      &.selected::after {
        opacity: 1;
      }
    }
  }
</style>