import { createStore } from 'vuex'
import router from '@/router'
import axios from 'axios'
import survey from '@/store/modules/survey'

export default createStore({
  modules: {
    survey
  },
  state: {
    opticienId: null,
    companyId: null,
    opticienData: null,
    age: null,
    screenTime: null,
    glassesAndOrLenses: null,
    sittingPosition: null,
    complaints: null,
    advice: null,
    credentials: null,
  },
  getters: {
    getResult: (state) => state,
    getOpticienData: (state) => state.opticienData,
    getCheckIfData: (state) => !!state.opticienData,
  },
  actions: {
    async fetchOpticienData({ commit, state }, data) {
      if (state.opticienData === null && state.opticienId !== data.opticienId || state.companyId !== data.companyId || state.opticienId === null) {
        try {
          const response = await axios.get(`https://zeiss-api.leadq.nl/GenericApi/Endpoint/GetShopInfo?divisionId=${data.opticienId}&companyId=${data.companyId}`, {
          });
          commit('setOpticienData', {response: response.data, opticienId: data.opticienId, companyId: data.companyId});
        } catch(error) {
          router.push('/verwijzing');
        }
      }
    },
    calculateAdvice({ commit, state }) {
      let count = 0;
      if (state.screenTime) {
        count++;
      }
      if (state.glassesAndOrLenses) {
        count++;
      }
      if (state.sittingPosition) {
        count++;
      }
      if (state.complaints) {
        count++;
      }

      if(count >= 1 && state.age.allowed) {
        commit('setAdvice', true);
      } else {
        commit('setAdvice', false);
      }
    },
    async sendData({ commit, state }) {
      // Send data to the api enpoint
      try {
        await axios.post(`https://zeiss-api.leadq.nl/GenericApi/Endpoint/PostCampaignResponses`, {
            companyId: state.companyId,
            campaign: 'Beeldschermbril',
            credentials: state.credentials,
            answers: {
              age: state.age.age,
              allowed: state.age.allowed,
              screenTime: state.screenTime,
              glassesAndOrLenses: state.glassesAndOrLenses,
              sittingPosition: state.sittingPosition,
              complaints: state.complaints,
              advice: state.advice,
            }
        });

        commit('clearState', true);
      } catch(error) {
        alert('Het is niet goed gegaan met het opslaan van je data, probeer het opnieuw of neem contact op met je HR medewerker.');
        router.push(`/start/${state.opticienId}/${state.companyId}`);
      }
    },
    async sendEmailData({ commit }, data) {
      try {
        const response = await axios.post(`https://zeiss-api.leadq.nl/GenericApi/Endpoint/sendBeeldschermbrilBeoordeling?result=${data.result}&refId=${data.refId}`);
        if (response.data.Error) {
          alert(response.data.Error);
        } else {
          (data.result === '1') ? router.push('/beeldschermbril-beoordelingscriteria-goedgekeurd') : router.push('/beeldschermbril-beoordelingscriteria-afgekeurd');
        }
      } catch(error) {
        alert('Er is een probleem opgetreden, probeer het nog een keer.');
      }
      commit('0');
    },
  },
  mutations: {
    setOpticienData(state, data) {
      state.opticienData = data.response;
      state.opticienId = data.opticienId;
      state.companyId = data.companyId;
    },
    setAge(state, data) {
      state.age = data;
      router.push(`/schermtijd/${state.opticienId}/${state.companyId}`);
    },
    setScreenTime(state, data) {
      state.screenTime = data;
      router.push(`/bril-of-en-lenzen/${state.opticienId}/${state.companyId}`);
    },
    setGlassesAndOrLenses(state, data) {
      state.glassesAndOrLenses = data;
      router.push(`/zithouding/${state.opticienId}/${state.companyId}`);
    },
    setSittingPosition(state, data) {
      state.sittingPosition = data;
      router.push(`/klachten/${state.opticienId}/${state.companyId}`);
    },
    setComplaints(state, data) {
      state.complaints = data;
      router.push(`/advies/${state.opticienId}/${state.companyId}`);
    },
    setAdvice(state, boolean) {
      state.advice = boolean;
    },
    setCredentials(state, data) {
      state.credentials = data;
    },
    clearState(state, data) {
      state.age = null;
      state.screenTime = null;
      state.glassesAndOrLenses = null;
      state.sittingPosition = null;
      state.complaints = null;
      state.advice = null;
      state.credentials = null;
      if (data) {
        router.push(`/verstuurd/${state.opticienId}/${state.companyId}`);
      } else {
        router.push(`/start/${state.opticienId}/${state.companyId}`);
      }
    }
  },
})
