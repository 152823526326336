import { createRouter, createWebHistory } from 'vue-router'
import Start from '@/views/Start.vue'
import Age from '@/views/Age.vue'
import ScreenTime from '@/views/ScreenTime.vue'
import GlassesLenses from '@/views/GlassesLenses.vue'
import SittingPosition from '@/views/SittingPosition.vue'
import Complaints from '@/views/Complaints.vue'
import Advice from '@/views/Advice.vue'
import CredentialsForm from '@/views/CredentialsForm.vue'
import Completed from '@/views/Completed.vue'
import Reference from '@/views/Reference.vue'
import RequestAccepted from '@/views/RequestAccepted.vue'
import RequestDenied from '@/views/RequestDenied.vue'
import InsertFromEmail from '@/views/InsertFromEmail.vue'
// Survey imports
import SurveyWelcome from '@/views/survey/Welcome';
import SurveyBefore from '@/views/survey/Before';
import SurveySatisfied from '@/views/survey/Satisfied';
import SurveyWorkingPosition from '@/views/survey/WorkingPosition';
import SurveyTiredEyes from '@/views/survey/TiredEyes';
import SurveySharperVision from '@/views/survey/SharperVision';
import SurveyLessPain from '@/views/survey/LessPain';
import SurveyService from '@/views/survey/Service';
import SurveyRecommend from '@/views/survey/Recommend';
import SurveyOtherGlasses from '@/views/survey/OtherGlasses';
import SurveyReview from '@/views/survey/Review';
import SurveyThanks from '@/views/survey/Thanks';

const routes = [
  {
    path: '/start/:opticienId/:companyId',
    name: 'Start',
    component: Start,
  },
  {
    path: '/leeftijd/:opticienId/:companyId',
    name: 'Leeftijd',
    component: Age,
  },
  {
    path: '/schermtijd/:opticienId/:companyId',
    name: 'Schermtijd',
    component: ScreenTime,
  },
  {
    path: '/bril-of-en-lenzen/:opticienId/:companyId',
    name: 'GlassesLenses',
    component: GlassesLenses,
  },
  {
    path: '/zithouding/:opticienId/:companyId',
    name: 'SittingPosition',
    component: SittingPosition,
  },
  {
    path: '/klachten/:opticienId/:companyId',
    name: 'Complaints',
    component: Complaints,
  },
  {
    path: '/advies/:opticienId/:companyId',
    name: 'Advice',
    component: Advice,
  },
  {
    path: '/gegevens/:opticienId/:companyId',
    name: 'Credentials',
    component: CredentialsForm,
  },
  {
    path: '/verstuurd/:opticienId/:companyId',
    name: 'Completed',
    component: Completed,
  },
  {
    path: '/verwijzing',
    name: 'Reference',
    component: Reference,
  },
  {
    path: '/beeldschermbril-beoordelingscriteria-goedgekeurd',
    name: 'Goedgekeurd',
    component: RequestAccepted,
  },
  {
    path: '/beeldschermbril-beoordelingscriteria-afgekeurd',
    name: 'Afgekeurd',
    component: RequestDenied,
  },
  {
    path: '/sendBeeldschermbrilBeoordeling',
    name: 'BeeldschermbrilBeoordeling',
    component: InsertFromEmail,
  },
  // Vragenlijst na akkoord
  {
    path: '/vragenlijst/:opticienId/:companyId',
    name: 'SurveyWelcome',
    component: SurveyWelcome,
  },
  {
    path: '/vragenlijst/:opticienId/:companyId/eerdere-bril',
    name: 'SurveyBefore',
    component: SurveyBefore,
  },
  {
    path: '/vragenlijst/:opticienId/:companyId/tevreden',
    name: 'SurveySatisfied',
    component: SurveySatisfied,
  },
  {
    path: '/vragenlijst/:opticienId/:companyId/werkhouding',
    name: 'SurveyWorkingPosition',
    component: SurveyWorkingPosition,
  },
  {
    path: '/vragenlijst/:opticienId/:companyId/vermoeide-ogen',
    name: 'SurveyTiredEyes',
    component: SurveyTiredEyes,
  },
  {
    path: '/vragenlijst/:opticienId/:companyId/scherper-zicht',
    name: 'SurveySharperVision',
    component: SurveySharperVision,
  },
  {
    path: '/vragenlijst/:opticienId/:companyId/minder-pijn',
    name: 'SurveyLessPain',
    component: SurveyLessPain,
  },
  {
    path: '/vragenlijst/:opticienId/:companyId/service',
    name: 'SurveyService',
    component: SurveyService,
  },
  {
    path: '/vragenlijst/:opticienId/:companyId/aanraden',
    name: 'SurveyRecommend',
    component: SurveyRecommend,
  },
  {
    path: '/vragenlijst/:opticienId/:companyId/andere-bril',
    name: 'SurveyOtherGlasses',
    component: SurveyOtherGlasses,
  },
  {
    path: '/vragenlijst/:opticienId/:companyId/ervaring',
    name: 'SurveyReview',
    component: SurveyReview,
  },
  {
    path: '/vragenlijst/:opticienId/:companyId/bedankt',
    name: 'SurveyThanks',
    component: SurveyThanks,
  },
  // {
    // path: '/about',
    // name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
  {
    path: '/:pathMatch(.*)*',
    redirect: "/verwijzing",
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
