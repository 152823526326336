<template>
  <NavBar :progress="60" titleSuffix="tevredenheidsonderzoek" customBase="vragenlijst/" />
  <div class="outer-container">
    <router-link :to="{ name: 'SurveySharperVision', params: { 'opticienId': $route.params.opticienId, 'companyId': $route.params.companyId } }">
      <img class="arrow-back" alt="arrow" src="../../assets/arrow-left.svg">
    </router-link>
    <div class="question">
      <div class="content-title">
        6 / 10
      </div>
<!--      Has used other glasses before-->
      <scale-question
        v-if="getUsedOther()"
        :selected-value="getLessPain()"
        @update:selectedValue="selectedValue"
        lowQuote="Ik merk geen verschil"
        highQuote="Ja, veel minder <i>met</i> de nieuwe beeldschermbril."
      >
        Heb je <i>met</i> je nieuwe beeldschermbril minder<br/>
        last van nek-, schouder- en/of rugpijn, dan <i>met</i><br/>
        <i>je andere beeldschermbril</i>?
      </scale-question>
<!--      Not used other glasses before -->
      <scale-question
        v-if="!getUsedOther()"
        :selected-value="getLessPain()"
        @update:selectedValue="selectedValue"
        lowQuote="Ik merk geen verschil"
        highQuote="Ja, veel minder <i>met</i> de nieuwe beeldschermbril."
      >
        Heb je met beeldschermbril minder last van nek-,<br/>
        schouder- en/of rugpijn, dan <i>zonder</i>?
      </scale-question>
    </div>
  </div>
</template>

<script>
  import NavBar from "@/components/NavBar";
  import ScaleQuestion from "@/components/ScaleQuestion";
  import {createNamespacedHelpers} from "vuex";
  const {mapGetters, mapMutations} = createNamespacedHelpers('survey');

  export default {
    name: "SurveyLessPain",
    components: {
      ScaleQuestion,
      NavBar
    },
    methods: {
      ...mapGetters(["getUsedOther", "getSharperVision", "getLessPain"]),
      ...mapMutations(["setLessPain"]),
      selectedValue(newValue) {
        this.setLessPain(newValue);
        this.$router.push(
          {
            name: 'SurveyService',
            params: {
              'opticienId': this.$route.params.opticienId,
              'companyId': this.$route.params.companyId
            }
          }
        );
      }
    },
    created() {
      // Navigate back when no previous answer found.
      if (this.getSharperVision() === undefined) this.$router.push(
          {
            name: 'SurveySharperVision',
            params: {
              'opticienId': this.$route.params.opticienId,
              'companyId': this.$route.params.companyId
            }
          }
      );
    }
  }
</script>

<style scoped>
  .content-title {
    font-weight: bold;
  }

  .question {
    margin-top: 8rem;

    display: inline-flex;
    flex-direction: column;
    gap: 1.5rem;
  }
</style>