<template>
  <NavBar :progress="0" />
  <div class="outer-container">
    <div class="succes-container">
      <img alt="completed" src="../assets/check.svg"><span>Aanvraag verstuurd</span>
    </div>
    <p>
      Jouw antwoorden worden allereerst zo snel mogelijk bekeken door een HR-medewerker<br> 
      in jouw bedrijf. Want de antwoorden voorspellen in grote mate of jij profijt<br> 
      kan hebben van een beeldschermbril. Bij een ‘positief’ resultaat kan je contact met<br>
      {{ (getCheckIfData) ? getOpticienData.divisionData.name : 'ons' }} opnemen. We plannen dan samen een meetafspraak voor een<br>
      beeldschermbril in.<br><br>
      Je krijgt van jouw HR-manager per e-mail bericht van de uitslag.<br><br>
      {{ (getCheckIfData) ? getOpticienData.divisionData.name : 'ons' }}
    </p>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Completed',
  components: {
    NavBar,
  },
  computed: {
    ...mapGetters(['getCheckIfData', 'getOpticienData']),
  },
}
</script>

<style lang="scss" scoped>
  .succes-container {
    margin-top: 12rem;
    display: flex;
    align-items: center;
    img {
      width: 3rem;
      margin-right: 1rem;
    }
    span {
      font-size: 1.27rem;
      color: #141e8c;
      font-weight: 600;
    }
  }

  p {
    margin-top: 2rem;
    font-size: 0.88rem;
    line-height: 1.8rem;
    color: #3d3d3d;
  }
</style>
